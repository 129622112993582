.listaRocket li p {background: url("../../../assets/img/home/arrowBullet.png") 0 4px no-repeat; padding-left: 15px;  font-size: 16px; margin-bottom:10px; color:#535353; }

.heroSAS {min-height: 700px; margin-top: 140px;}
.heroSASImg {position: absolute; right: -270px; top: -40px; z-index: 0;}
.heroEmpresaLista {padding-top: 80px; padding-bottom: 80px;}
.bgTodoIncluido {
    padding-top: 80px; padding-bottom: 80px;     
    background: rgba(244,244,244,1);
    background: -moz-linear-gradient(top, rgba(244,244,244,1) 0%, rgba(246,246,246,0.89) 53%, rgba(255,255,255,0.8) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(244,244,244,1)), color-stop(53%, rgba(246,246,246,0.89)), color-stop(100%, rgba(255,255,255,0.8)));
    background: -webkit-linear-gradient(top, rgba(244,244,244,1) 0%, rgba(246,246,246,0.89) 53%, rgba(255,255,255,0.8) 100%);
    background: -o-linear-gradient(top, rgba(244,244,244,1) 0%, rgba(246,246,246,0.89) 53%, rgba(255,255,255,0.8) 100%);
    background: -ms-linear-gradient(top, rgba(244,244,244,1) 0%, rgba(246,246,246,0.89) 53%, rgba(255,255,255,0.8) 100%);
    background: linear-gradient(to bottom, rgba(244,244,244,1) 0%, rgba(246,246,246,0.89) 53%, rgba(255,255,255,0.8) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f4f4', endColorstr='#ffffff', GradientType=0 );
}
.bgServAdic  {
    border-radius: 10px;
    padding: 30px;     
background: rgba(162,76,2,1);
background: -moz-linear-gradient(left, rgba(162,76,2,1) 0%, rgba(246,137,43,1) 100%);
background: -webkit-gradient(left top, right top, color-stop(0%, rgba(162,76,2,1)), color-stop(100%, rgba(246,137,43,1)));
background: -webkit-linear-gradient(left, rgba(162,76,2,1) 0%, rgba(246,137,43,1) 100%);
background: -o-linear-gradient(left, rgba(162,76,2,1) 0%, rgba(246,137,43,1) 100%);
background: -ms-linear-gradient(left, rgba(162,76,2,1) 0%, rgba(246,137,43,1) 100%);
background: linear-gradient(to right, rgba(162,76,2,1) 0%, rgba(246,137,43,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a24c02', endColorstr='#f6892b', GradientType=1 );

}

.listaMasServAdic {background: url("../../../assets/img/sasWizard/iconMore.png") 0 7px no-repeat; padding-left: 15px;  font-size: 16px; margin-bottom:10px; color:#fff}

.caractSasWizard {margin-top:20px;border-radius: 10px; background-color: #0365A6 !important; color:#fff !important; min-height: 80px; padding: 0 20px 0 20px; display: flex; justify-content: center; align-items: center; flex-direction: column;}
.caractSasWizard p {font-size: 1rem; margin:0;}

.titServAdic {color:#fff !important; font-size: 1.9rem;}

.titComiezaAhoraSAS {color:#000 !important; font-size: 1.5rem;}

@media (max-width: 767px) {
    .heroSAS {min-height: auto; padding-top: 50px; margin-top: 20px;}
    .heroSASImg {position: relative; right: -10px; top:40px; z-index: 0; max-width: 250px; margin:0px auto;}
    .heroEmpresaLista {padding-top: 40px; padding-bottom: 40px;}
    .bgServAdic .botones {width: 100% !important;}
}


