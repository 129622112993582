.mainDocumentos {
  margin: 80px 0 0 0;
  background: #f5fbfc;
}

.bgMainDocu {
  background: #f5fbfc
    url("../../../assets/img/documentos/imgDocumentoValido1080.jpg") top left
    no-repeat;
  background-size: 50% auto;
}

@media (max-width: 1366px) {
  .bgMainDocu {
    background: #f5fbfc
      url("../../../assets/img/documentos/imgDocumentoValido.png") top left
      no-repeat;
  }
}

.mainInvalido {
  margin: 80px 0 0 0;
  background: #f5fbfc;
}

.docValido {
  height: calc(100vh - 80px);
}

.iconDocValido {
  max-height: 250px;
  background: #fff;
  border-radius: 20px;
  padding: 20px;
  -webkit-box-shadow: 0px 0px 31px 11px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 0px 31px 11px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 0px 31px 11px rgba(0, 0, 0, 0.16);
}

.containerDocu {
  padding-top: 150px;
}
.mainDocumentos h2,
.mainInvalido h2 {
  color: #0365a6 !important;
}
.textDocu {
  font-size: 1rem;
  color: #222222;
  margin: 0;
}

.footerDocumento {
  color: #004674;
  font-size: 12px;
  background: url("../../../assets/img/documentos/logoGlobalStartupFooter.png")
    top left no-repeat;
  padding: 10px 0 10px 50px;
}

@media (max-width: 767px) {
  .containerDocu {
    padding-top: 80px;
  }

  .footerDocumento {
    background: url("../../../assets/img/documentos/logoGlobalStartupFooter.png")
      top center no-repeat;
    padding: 50px 0 0 0;

    text-align: center;
  }

  .iconDocValido {
    margin-bottom: 20px;
  }
}

@media (max-width: 991px) {
  .bgMainDocu {
    background-image: none;
  }
}
