.introSasWizardBlue {
  color: #2866a7;
  font-size: 2rem;
  font-weight: 700;
  margin: 0;
  line-height: 2.3rem;
  padding-top: 30px;
}

.txtPagoUnico {
  color: #2866a7;
  padding-top: 15px;
  padding-bottom: 13px;
}

.fndBoxPreciosRocket {
  border-radius: 20px;
  background: url("../../../../assets/img/rocketContracts/bgBievenidiaRocket.png")
    top center no-repeat;
  height: 260px;
  margin-bottom: 40px;
  padding: 40px 60px;
  margin-left: 20px;
}

@media (max-width: 991px) {
  .fndBoxPreciosRocket {
    height: auto;
    margin-bottom: 40px;
    background: #0365a6 !important;
    border-radius: 20px;
    margin-left: 0px;
  }
}
