.filtroRecursos .input-group-text {background: transparent;}
.filtroRecursos .searchInput {border-left: 0 !important; padding-left:0 !important; }
.filtroRecursos .selectCategory {width: 100%;}
.filtroRecursos p {font-size: 0.9rem;}
.resultadoRecursos h5 {color:#222222; font-size: 1rem; font-weight: 700; margin:0}
.resultadoRecursos h5 span {color:#0365A6; font-size: 11px; font-weight: 400; background: url("../../../assets/img/recursosDigitales/iconVistaPrevia.png") 0 1px no-repeat; padding-left: 20px; margin-left: 15px;}
.textoRecursos {font-size: 0.9rem; margin:0 }
.borderRecursos {border: 1px solid #E0E1E1; border-radius: 10px; padding: 13px; margin-bottom: 15px;}
.borderRecursos.active {border: 2px solid #198356;}
.textoPriceRecursos {font-size: 0.9rem; font-weight: 700;}


.bgOrder {background-color: #f7f7f7 !important; border-radius: 10px; padding: 35px 20px;}
.bgOrder .titOrder {font-size: 1.1rem; font-weight: 700; border-bottom: 1px solid #E0E1E1; padding-bottom: 10px; margin-bottom: 20px;}

.bgOrder hr {border-bottom: 1px solid #E0E1E1; border-top: 0; margin: 10px 0;}
.DatosOrden p {font-size: 0.9rem; color:#222; margin:0}
.DatosOrden span {font-size: 0.7rem; color:#535353 }

.bgOrderTotal {font-weight: 700; text-transform: uppercase;}

@media (max-width: 767px) {
    .bgOrder { margin-top: 30px;}
.resultadoRecursosh5 {margin-top:15px}
.textoRecursos {font-size: 1rem; margin:0 }
}

