.bgTerminos {    
background:  url("../../../assets/img/home/fondoFAQSGs360Home.png") bottom right no-repeat rgba(245,245,245,1);
padding: 0 !important; margin:0 !important; padding-bottom: 150px !important; padding-top: 120px !important; 
}

.titTerminos {color:#15385C!important; font-size: 1.7rem; font-weight: 300; margin: 0;}
.subTitTerminos {color:#222 !important; font-size: 1.1rem; font-weight: 400; margin: 0;}

.seccionTerminos {padding-bottom:100px;}
.seccionTerminos h4 {font-size: 1.4rem; font-weight: 700; padding-top: 40px; padding-bottom: 20px;}

@media (max-width: 767px) {
    .bgTerminos {background: #fff !important; padding: 70px 0 120px 0 !important; margin:0 !important}
        
}