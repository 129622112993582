@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,400;0,600;0,900;1,400;1,700&display=swap");
@import url("https://use.typekit.net/doa2lnw.css");

body {
  font-family: "Nunito Sans", sans-serif !important;
  margin: 0;
  overflow-x: hidden !important;
}

body.modal-open {
  width: 100% !important;
  padding-right: 0 !important;
  overflow-y: scroll !important;
}

body.modal-open .topNavbar {
  margin-right: 0 !important;
  padding-right: 15px !important;
}

/* FIX FOR MAC BUTTONS */
input:focus {
  outline: none;
}
button:focus {
  outline: none;
}

html {
  font-size: 17px;
}
@media (max-width: 1000px) {
  html {
    font-size: 16px;
  }
}
@media (max-width: 900px) {
  html {
    font-size: 15px;
  }
}
@media (max-width: 400px) {
  html {
    font-size: 14px;
  }
}

a {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
a:hover {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

button {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
button:hover {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.btnVerde {
  background: #0ff778 !important;
  color: #000 !important;
  border: 1px solid #0ff778 !important;
  border-radius: 5px;
  font-size: 14px !important;
  padding: 13px 25px !important;
  text-transform: uppercase;
  letter-spacing: 0.1px;
  min-height: 50px;
}
.btnVerde:hover {
  background: #0dc0ce !important;
  color: #fff !important;
  border: 1px solid #0dc0ce !important;
}

/*----------- HERO ---------------*/

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: sofia-pro, sans-serif !important;
}

.naranja {
  color: #ed7003 !important;
}
.verde {
  color: #198356 !important;
}

.titHero {
  color: #15385c;
  font-size: 2.8rem;
  font-weight: 700;
  margin: 0;
  padding-bottom: 15px;
  line-height: 2.7rem;
}
.subtitHero {
  color: #5ac3dc;
  font-size: 2.8rem;
  font-weight: 700;
  margin: 0;
  padding-bottom: 15px;
  line-height: 2.7rem;
}
.heroText {
  color: #222 !important;
  font-size: 1.2rem;
  font-weight: 400;
  margin: 0;
  line-height: 1.8rem;
}
.heroSubText {
  color: #222 !important;
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  line-height: 1.4rem;
}

.light .titHero {
  color: #fff !important;
}
.light .subtitHero {
  color: #16bcbb !important;
}
.light .heroText {
  color: #fff !important;
}

.border-radius-5 {
  border-radius: 10px;
}

.text700 {
  font-weight: 700;
}

.titAzul {
  color: #15385c;
  font-size: 2.1rem;
}
.titAzul {
  color: #000;
  font-size: 2.1rem;
}

.linkFAQ {
  color: #2c2f3c;
  text-decoration: underline;
}
.linkFAQ:hover {
  color: #15385c;
  text-decoration: underline;
}

/*------------------------ BOTONES -----------------------*/

.botones {
  min-height: 50px;
  border-radius: 10px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 12px;
  border: 2px solid;
  margin: 5px;
}
.botones:hover,
.botones:focus,
.botones:active,
.botones.active {
  text-decoration: none !important;
}

.btnAzul {
  color: #ffffff;
  background-color: #0365a6;
  border-color: #0365a6;
}

.btnAzul:hover,
.btnAzul:focus,
.btnAzul:active,
.btnAzul.active,
.open .dropdown-toggle.btnAzul {
  color: #ffffff;
  background-color: #fdb21c;
  border-color: #fdb21c;
}

.btnAzul:active,
.btnAzul.active,
.open .dropdown-toggle.btnAzul {
  background-image: none;
}

.btnAzul.disabled,
.btnAzul[disabled],
fieldset[disabled] .btnAzul,
.btnAzul.disabled:hover,
.btnAzul[disabled]:hover,
fieldset[disabled] .btnAzul:hover,
.btnAzul.disabled:focus,
.btnAzul[disabled]:focus,
fieldset[disabled] .btnAzul:focus,
.btnAzul.disabled:active,
.btnAzul[disabled]:active,
fieldset[disabled] .btnAzul:active,
.btnAzul.disabled.active,
.btnAzul[disabled].active,
fieldset[disabled] .btnAzul.active {
  background-color: #0365a6;
  border-color: #0365a6;
}

.btnAzulExtra {
  color: #ffffff;
  background-color: #0365a6;
  border-color: #0365a6;
}

.btnAzulExtra:hover,
.btnAzulExtra:focus,
.btnAzulExtra:active,
.btnAzulExtra.active,
.open .dropdown-toggle.btnAzulExtra {
  color: #0365a6;
  background-color: #fff;
  border-color: #0365a6;
}

.btnAzulDarkHover {
  color: #ffffff;
  background-color: #fdb21c;
  border-color: #fdb21c;
}

.btnAzulDarkHover:hover,
.btnAzulDarkHover:focus,
.btnAzulDarkHover:active,
.btnAzulDarkHover.active,
.open .dropdown-toggle.btnAzulDarkHover {
  color: #fff;
  background-color: #15385c;
  border-color: #15385c;
}

.light .btnAzul {
  color: #0365a6;
  background-color: #fff;
  border-color: #fff;
}

.light .btnAzul:hover,
.light .btnAzul:focus,
.light .btnAzul:active,
.light .btnAzul.active,
.open .dropdown-toggle.btnAzul {
  color: #ffffff;
  background-color: #fdb21c;
  border-color: #fdb21c;
  text-shadow: 0 0 0.65px #fff, 0 0 0.65px #fff;
}

.light .btnAzul:active,
.light .btnAzul.active,
.light .open .dropdown-toggle.btnAzul {
  background-image: none;
}

.light .btnAzul.disabled,
.light .btnAzul[disabled],
fieldset[disabled] .light .btnAzul,
.light .btnAzul.disabled:hover,
.light .btnAzul[disabled]:hover,
fieldset[disabled] .light .btnAzul:hover,
.light .btnAzul.disabled:focus,
.light .btnAzul[disabled]:focus,
fieldset[disabled] .light .btnAzul:focus,
.light .btnAzul.disabled:active,
.light .btnAzul[disabled]:active,
fieldset[disabled] .light .btnAzul:active,
.light .btnAzul.disabled.active,
.light .btnAzul[disabled].active,
fieldset[disabled] .light .btnAzul.active {
  background-color: #0365a6;
  border-color: #0365a6;
}

/**************/

.btnOutlineAzul {
  color: #222;
  background-color: transparent;
  border-color: transparent;
}

.btnOutlineAzul:hover,
.btnOutlineAzul:focus,
.btnOutlineAzul:active,
.btnOutlineAzul.active,
.open .dropdown-toggle.btnOutlineAzul {
  color: #0365a6;
  background-color: transparent;
  border-color: #0365a6 !important;
  text-shadow: 0 0 0.65px #0365a6, 0 0 0.65px #0365a6;
}

.btnOutlineAzul:active,
.btnOutlineAzul.active,
.open .dropdown-toggle.btnOutlineAzul {
  background-image: none;
}

.btnOutlineAzul.disabled,
.btnOutlineAzul[disabled],
fieldset[disabled] .btnOutlineAzul,
.btnOutlineAzul.disabled:hover,
.btnOutlineAzul[disabled]:hover,
fieldset[disabled] .btnOutlineAzul:hover,
.btnOutlineAzul.disabled:focus,
.btnOutlineAzul[disabled]:focus,
fieldset[disabled] .btnOutlineAzul:focus,
.btnOutlineAzul.disabled:active,
.btnOutlineAzul[disabled]:active,
fieldset[disabled] .btnOutlineAzul:active,
.btnOutlineAzul.disabled.active,
.btnOutlineAzul[disabled].active,
fieldset[disabled] .btnOutlineAzul.active {
  color: #0365a6;
  background-color: #0365a6;
  border-color: #0365a6;
  text-shadow: 0 0 0.65px #0365a6, 0 0 0.65px #0365a6;
}

.light .btnOutlineAzul {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}

.light .btnOutlineAzul:hover,
.light .btnOutlineAzul:focus,
.light .btnOutlineAzul:active,
.light .btnOutlineAzul.active,
.open .dropdown-toggle.btnOutlineAzul {
  color: #fff;
  background-color: transparent;
  border-color: #fff !important;
  text-shadow: 0 0 0.65px #0365a6, 0 0 0.65px #0365a6;
}

.light .btnOutlineAzul:active,
.light .btnOutlineAzul.active,
.open .dropdown-toggle.btnOutlineAzul {
  background-image: none;
}

.light .btnOutlineAzul.disabled,
.light .btnOutlineAzul[disabled],
fieldset[disabled] .light .btnOutlineAzul,
.light .btnOutlineAzul.disabled:hover,
.light .btnOutlineAzul[disabled]:hover,
fieldset[disabled] .light .btnOutlineAzul:hover,
.light .btnOutlineAzul.disabled:focus,
.light .btnOutlineAzul[disabled]:focus,
fieldset[disabled] .light .btnOutlineAzul:focus,
.light .btnOutlineAzul.disabled:active,
.light .btnOutlineAzul[disabled]:active,
fieldset[disabled] .light .btnOutlineAzul:active,
.light .btnOutlineAzul.disabled.active,
.light .btnOutlineAzul[disabled].active,
fieldset[disabled] .light .btnOutlineAzul.active {
  color: #0365a6;
  background-color: #0365a6;
  border-color: #0365a6;
  text-shadow: 0 0 0.65px #0365a6, 0 0 0.65px #0365a6;
}

/*********************/

.btnNaranja {
  color: #ffffff;
  background-color: #fdb21c;
  border-color: #fdb21c;
}

.btnNaranja:hover,
.btnNaranja:focus,
.btnNaranja:active,
.btnNaranja.active,
.light .open .dropdown-toggle.btnNaranja {
  color: #ffffff;
  background-color: #0365a6;
  border-color: #0365a6;
}

.btnNaranja:active,
.btnNaranja.active,
.open .dropdown-toggle.btnNaranja {
  background-image: none;
}

.btnNaranja.disabled,
.btnNaranja[disabled],
fieldset[disabled] .btnNaranja,
.btnNaranja.disabled:hover,
.btnNaranja[disabled]:hover,
fieldset[disabled] .btnNaranja:hover,
.btnNaranja.disabled:focus,
.btnNaranja[disabled]:focus,
fieldset[disabled] .btnNaranja:focus,
.btnNaranja.disabled:active,
.btnNaranja[disabled]:active,
fieldset[disabled] .btnNaranja:active,
.btnNaranja.disabled.active,
.btnNaranja[disabled].active,
fieldset[disabled] .btnNaranja.active {
  background-color: #0365a6;
  border-color: #0365a6;
}

.btnNaranjaExtra {
  color: #ffffff;
  background-color: #fdb21c;
  border-color: #fdb21c;
}

.btnNaranjaExtra:hover,
.btnNaranjaExtra:focus,
.btnNaranjaExtra:active,
.btnNaranjaExtra.active,
.light .open .dropdown-toggle.btnNaranjaExtra {
  color: #fdb21c;
  background-color: #fff;
  border-color: #fdb21c;
  text-shadow: 0 0 0.65px #fff, 0 0 0.65px #fff;
}

/************/

.btnCeleste {
  color: #fff;
  background-color: #00c3c3;
  border-color: #00c3c3;
}

.btnCeleste:hover,
.btnCeleste:focus,
.btnCeleste:active,
.btnCeleste.active,
.open .dropdown-toggle.btnAzul {
  color: #00c3c3;
  background-color: #fff;
  border-color: #fff;
  text-shadow: 0 0 0.65px #fff, 0 0 0.65px #fff;
}

.btnCeleste:active,
.btnCeleste.active,
.open .dropdown-toggle.btnAzul {
  background-image: none;
}

.btnCeleste.disabled,
.btnCeleste[disabled],
fieldset[disabled] .btnAzul,
.btnCeleste.disabled:hover,
.btnCeleste[disabled]:hover,
fieldset[disabled] .btnAzul:hover,
.btnCeleste.disabled:focus,
.btnCeleste[disabled]:focus,
fieldset[disabled] .btnAzul:focus,
.btnCeleste.disabled:active,
.btnCeleste[disabled]:active,
fieldset[disabled] .btnAzul:active,
.btnCeleste.disabled.active,
.btnCeleste[disabled].active,
fieldset[disabled] .btnAzul.active {
  background-color: #00c3c3;
  border-color: #00c3c3;
}

.btnCelesteExtra {
  color: #fff;
  background-color: #00c3c3;
  border-color: #00c3c3;
}

.btnCelesteExtra:hover,
.btnCelesteExtra:focus,
.btnCelesteExtra:active,
.btnCelesteExtra.active,
.open .dropdown-toggle.CelesteExtra {
  color: #00c3c3;
  background-color: #fff;
  border-color: #00c3c3;
  text-shadow: 0 0 0.65px #fff, 0 0 0.65px #fff;
}
/***********************/

.btnVioletaInverse {
  color: #fff;
  background-color: #951cfd;
  border-color: #951cfd;
}

.btnVioletaInverse:hover,
.btnVioletaInverse:focus,
.btnVioletaInverse:active,
.btnVioletaInverse.active,
.open .dropdown-toggle.btnVioleta {
  color: #951cfd;
  background-color: #fff;
  border-color: #951cfd;
  text-shadow: 0 0 0.65px #fff, 0 0 0.65px #fff;
}

.btnVioletaInverse.active:hover {
  color: #fff;
  background-color: #951cfd;
  border-color: #951cfd;
}

.btnVioleta {
  color: #951cfd;
  background-color: #fff;
  border-color: #fff;
}

.light .btnVioleta:hover,
.light .btnVioleta:focus,
.light .btnVioleta:active,
.light .btnVioleta.active,
.light .open .dropdown-toggle.btnVioleta {
  color: #fff;
  background-color: #951cfd;
  border-color: #951cfd;
  text-shadow: 0 0 0.65px #fff, 0 0 0.65px #fff;
}

.light .btnVioleta:active,
.light .btnVioleta.active,
.light .open .dropdown-toggle.btnVioleta {
  background-image: none;
}

.light .btnVioleta.disabled,
.light .btnVioleta[disabled],
fieldset[disabled] .light .btnVioleta,
.light .btnVioleta.disabled:hover,
.light .btnVioleta[disabled]:hover,
fieldset[disabled] .light .btnVioleta:hover,
.light .btnVioleta.disabled:focus,
.light .btnVioleta[disabled]:focus,
fieldset[disabled] .light .btnVioleta:focus,
.light .btnVioleta.disabled:active,
.light .btnVioleta[disabled]:active,
fieldset[disabled] .light .btnVioleta:active,
.light .btnVioleta.disabled.active,
.light .btnVioleta[disabled].active,
fieldset[disabled] .light .btnVioleta.active {
  background-color: #fff;
  border-color: #fff;
}

/*********************/

.btnNaranjaInverse {
  color: #ed7003;
  background-color: #fff;
  border-color: #fff;
}

.btnNaranjaInverse:hover,
.btnNaranjaInverse:focus,
.btnNaranjaInverse:active,
.btnNaranjaInverse.active,
.light .open .dropdown-toggle.btnNaranjaInverse {
  color: #ffffff;
  background-color: #ed7003;
  border-color: #ed7003;
  text-shadow: 0 0 0.65px #fff, 0 0 0.65px #fff;
}

.btnNaranjaInverse:active,
.btnNaranjaInverse.active,
.open .dropdown-toggle.btnNaranjaInverse {
  background-image: none;
}

.btnNaranjaInverse.disabled,
.btnNaranjaInverse[disabled],
fieldset[disabled] .btnNaranjaInverse,
.btnNaranjaInverse.disabled:hover,
.btnNaranjaInverse[disabled]:hover,
fieldset[disabled] .btnNaranjaInverse:hover,
.btnNaranjaInverse.disabled:focus,
.btnNaranjaInverse[disabled]:focus,
fieldset[disabled] .btnNaranjaInverse:focus,
.btnNaranjaInverse.disabled:active,
.btnNaranjaInverse[disabled]:active,
fieldset[disabled] .btnNaranjaInverse:active,
.btnNaranjaInverse.disabled.active,
.btnNaranjaInverse[disabled].active,
fieldset[disabled] .btnNaranjaInverse.active {
  background-color: #ed7003;
  border-color: #ed7003;
}

/************/

.btnNaranjaNegro {
  color: #fff;
  background-color: #fff;
  background: rgb(246, 137, 43);
  background: linear-gradient(
    90deg,
    rgba(246, 137, 43, 1) 0%,
    rgba(162, 76, 2, 1) 100%
  );
  border: 0;
}

.btnNaranjaNegro:hover,
.btnNaranjaNegro:focus,
.btnNaranjaNegro:active,
.btnNaranjaNegro.active {
  color: #ffffff;
  background-color: #000;
  border-color: #ed7003;
  text-shadow: 0 0 0.65px #fff, 0 0 0.65px #fff;
}

/************/

.btnCelesteDark {
  color: #fff;
  background-color: #00c3c3;
  border-color: #00c3c3;
}

.btnCelesteDark:hover,
.btnCelesteDark:focus,
.btnCelesteDark:active,
.btnCelesteDark.active,
.open .dropdown-toggle.btnCelesteDark {
  color: #00c3c3;
  background-color: #fff;
  border-color: #00c3c3;
  text-shadow: 0 0 0.65px #fff, 0 0 0.65px #fff;
}

.btnCelesteDark.active:hover {
  color: #fff;
  background-color: #00c3c3;
  border-color: #00c3c3;
}

.btnCelesteDark:active,
.btnCelesteDark.active,
.open .dropdown-toggle.btnCelesteDark {
  background-image: none;
}

.btnCelesteDark.disabled,
.btnCelesteDark[disabled],
fieldset[disabled] .btnAzul,
.btnCelesteDark.disabled:hover,
.btnCelesteDark[disabled]:hover,
fieldset[disabled] .btnAzul:hover,
.btnCelesteDark.disabled:focus,
.btnCelesteDark[disabled]:focus,
fieldset[disabled] .btnAzul:focus,
.btnCelesteDark.disabled:active,
.btnCelesteDark[disabled]:active,
fieldset[disabled] .btnAzul:active,
.btnCelesteDark.disabled.active,
.btnCelesteDark[disabled].active,
fieldset[disabled] .btnAzul.active {
  background-color: #00c3c3;
  border-color: #00c3c3;
}

.btnCelesteDark {
  color: #fff;
  background-color: #00c3c3;
  border-color: #00c3c3;
}

/*******************************/

.btnVerdeDark {
  color: #fff;
  background-color: #198356;
  border: 2px solid #198356;
}

.btnVerdeDark:hover,
.btnVerdeDark:focus,
.btnVerdeDark:active,
.btnVerdeDark .active {
  color: #198356;
  background-color: #fff;
  border-color: #198356;
  text-shadow: 0 0 0.65px #fff, 0 0 0.65px #fff;
}

.btnVerdeDarkInverse {
  color: #198356;
  background-color: #fff;
  border: 2px solid #fff;
}

.btnVerdeDarkInverse:hover,
.btnVerdeDarkInverse:focus,
.btnVerdeDarkInverse:active,
.btnVerdeDark .active {
  color: #fff;
  background-color: #198356;
  border: 2px solid #198356;
  text-shadow: 0 0 0.65px #fff, 0 0 0.65px #fff;
}

.btnVerdeDarkOutline {
  color: #198356;
  background-color: #fff;
  border: 2px solid #198356;
}

.btnVerdeDarkOutline:hover,
.btnVerdeDarkOutline:focus,
.btnVerdeDarkOutline:active,
.btnVerdeDark .active {
  color: #fff;
  background-color: #198356;
  border: 2px solid #198356;
  text-shadow: 0 0 0.65px #fff, 0 0 0.65px #fff;
}

.btnNaranjaStrong {
  color: #fff;
  background-color: #ed7003;
  border: 2px solid #ed7003;
}

.btnNaranjaStrong:hover,
.btnNaranjaStrong:focus,
.btnNaranjaStrong:active,
.btnNaranjaStrong .active {
  color: #ed7003;
  background-color: #fff;
  border-color: #ed7003;
  text-shadow: 0 0 0.65px #fff, 0 0 0.65px #fff;
}

/*****************************/

.btnOutlineNegro {
  color: #222;
  background-color: transparent;
  border: 2px solid #222;
}

.btnOutlineNegro:hover,
.btnOutlineNegro:focus,
.btnOutlineNegro:active,
.btnOutlineNegro.active,
.open .dropdown-toggle.btnOutlineNegro {
  color: #fff;
  background-color: #222;
  border-color: #222 !important;
  text-shadow: 0 0 0.65px #fff, 0 0 0.65px #fff;
}

.paginationProv.pagination {
  justify-content: center !important;
  margin-top: 20px;
}

.paginationProv.pagination .page-link {
  margin: 4px;
  border-radius: 5px;
}

.paginationProv.pagination .page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0365a6;
  border-color: #0365a6;
  margin: 4px;
  border-radius: 5px;
}

.mega-dropdown {
  position: static !important;
  width: 100%;
}

.mega-dropdown-menu {
  padding: 20px 0px;
  width: 100%;
  box-shadow: none;
  -webkit-box-shadow: none;
}
