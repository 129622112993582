.boxPrecios h6 {color:#FFF; font-size: 2rem; font-weight: 700;}
.boxPrecios p {color:#222; font-size: 0.9rem; font-weight: 400; margin-bottom:0}
.fndBoxPrecios {border-radius: 20px; background: url("../../../assets/img/precios/fondoSasPrecios.svg") top center no-repeat;  min-height: 390px; margin-bottom: 40px; padding: 50px;}
.linkWhite {color: #fff;  text-decoration: underline;}
.linkWhite:hover {color: #FDB21C; text-decoration: underline;}
.listaPreciosSpecs .col {background: url("../../../assets/img/precios/iconCheckList.png") 15px 4px no-repeat; padding-left: 40px;  font-size: 16px; margin-bottom:10px; color:#fff; text-align: left ; }
.listaPreciosSpecs p {color:#fff; text-align: left ; }

.listaOptionsRocket .col {background: url("../../../assets/img/precios/iconCheckListBlue.png") 15px 6px no-repeat; padding-left: 40px;  font-size: 16px; margin-bottom:10px; color:#fff; text-align: left ; }
.listaOptionsRocket p {color:#000; text-align: left ; margin-bottom: 5px; }

.precioOpcion {font-size: 1rem; margin: 0;}
.precioOpcionspan {color:#7C7C7C !important}
.fndBoxOpciones {background-color: #F2F2F2; border-radius: 20px; padding: 30PX;}
.fndBoxOpciones p {line-height: 1.5rem; }
.mhTxtPrec {min-height: 150px}
.w100xsOption {width: 200px ;}
@media (max-width:  991px) {
    .fndBoxPrecios  { min-height: auto; margin-bottom: 40px; background: #0365a6 !important; border-radius: 20px;} 
    .w100xsOption {width: 100% ;}
    .mhTxtPrec {min-height: auto}
}