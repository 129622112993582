.topNavbar {z-index: 1039 !important; position: absolute;}

.linkHeader {color:#222; font-size: 16px; font-weight: 400; padding: 15px;}
.linkHeader:hover,
.linkHeader:active,
.linkHeader:focus
 {color:#5ac3dc; text-decoration:underline; text-underline-offset: 10px; text-shadow: 0 0 .65px #5ac3dc, 0 0 .65px #5ac3dc;}


.light .linkHeader {color:#FFF; font-size: 16px; font-weight: 400; padding: 10px; margin: 5px;}
.light .linkHeader:hover,
.light .linkHeader:active,
.light .linkHeader:focus
 {color:#5ac3dc; text-decoration:underline; text-underline-offset: 10px; text-shadow: 0 0 .65px #5ac3dc, 0 0 .65px #5ac3dc;}

.subtitNavBarMM {font-size: 14px;}
.bgDerNavBar {background: #15385c; border-top-right-radius: 10px;border-bottom-right-radius: 10px; padding: 30px;}
.bgDerNavBar h6 {color:#FFF !important; font-size: 1.3rem;}

.txtSolNavBar {font-size: 15px; font-weight: bold; color:#222; text-decoration: none !important;}
.txtSolNavBar img {margin-right:5px}
.linkSolNavBar:hover { text-decoration: none !important;}
.linkSolNavBar:hover p { text-decoration: none !important;}

.topNavbar .dropdown-menu {
	padding: 0 !important;
    margin: 0 !important;
    border: 0 !important;
    border-radius: 10px;
    padding-left: 40px !important;
   
    -webkit-box-shadow: 0px 0px 13px 3px rgba(0,0,0,0.16);
-moz-box-shadow: 0px 0px 13px 3px rgba(0,0,0,0.16);
box-shadow: 0px 0px 13px 3px rgba(0,0,0,0.16);
}
.topNavbar .dropdown-menu .dropdown-item-text{
	padding: 0 !important;
    margin: 0 !important;
  
}

.dropdown-toggle.btn.btn-primary {
    background: none !important;
    border: 0;
    font-size: 16px;
    color:#222 !important
}

.dropdown-toggle { height: 100%;}

.mega-dropdown.show.dropdown button:focus{
    background: transparent !important;
    border:0 !important;
    box-shadow: none !important;
}


.light .dropdown-toggle.btn.btn-primary {  
    color:#fff !important
}

.dropdown-toggle.btn.btn-primary:hover {
    color:#5ac3dc !important; text-decoration:underline !important; text-underline-offset: 10px !important; text-shadow: 0 0 .65px #5ac3dc, 0 0 .65px #5ac3dc !important;
}

.dropdown-toggle.btn.btn-primary:focus {
     box-shadow: none !important;}

.navbar-collapse {
    align-items: flex-end !important;
}

 .show > .btn-primary.dropdown-toggle {
	background-color:transparent !important;
	border:0 !important; 
}

.show > .btn-primary.dropdown-toggle:focus {
    border:0 !important;
    background-color:transparent !important;
}

 .navbar {padding:  15px 0 0 0 !important;}
 .navbar-brand {padding:  0 !important;}

.menuSolucionesTop .dropdown-menu.show {left:200px !important} 


 .sticky-top {-webkit-transition: all 0.2s ease;  -moz-transition: all 0.2s ease;  -ms-transition: all 0.2s ease;  -o-transition: all 0.2s ease; transition: all 0.2s ease; position: fixed !important;}
 .sticky-top .navbar .navbar-brand {width: 150px;}
 .scrolled{ background-color: #FFF;  -webkit-box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.27);
    -moz-box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.27);
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.27);  -webkit-transition: all 0.2s ease;  -moz-transition: all 0.2s ease;  -ms-transition: all 0.2s ease;  -o-transition: all 0.2s ease; transition: all 0.2s ease; }

    .sticky-top.scrolled .navbar {padding: 0 !important;}
    .sticky-top.scrolled .navbar .navbar-brand img {max-width: 100px;}

.divisorNavBar {height:50px; width: 1px; background-color: #666; margin: 5px 20px; }    
.light .divisorNavBar { background-color: #FFF;  }    

@media (max-width: 767px) {
    .navbar-collapse { align-items: flex-start !important;}
    .divisorNavBar {width: 0 !important; }   
    .topNavbar {z-index: 1050 !important; position: relative;}
    .sticky-top { background-color: #FFF; padding-top: 10px;	padding-bottom: 10px;  -webkit-transition: all 0.2s ease;  -moz-transition: all 0.2s ease;  -ms-transition: all 0.2s ease;  -o-transition: all 0.2s ease; transition: all 0.2s ease; }
    .navbar {padding: 15px 0 15px 0;}
    .navbar-brand {max-width: 65%; margin: 0 !important; }
    .topNavbar .dropdown-menu {padding-left: 0  !important; background: transparent; box-shadow: none !important}
    .dropdown-toggle { height: auto !important;}
    .dropdown-toggle.btn.btn-primary {
	margin-left: 5px !important;
	margin-top: 15px;
}

}
