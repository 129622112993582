.mainLegalManager {padding: 100px 0 100px 0;}
.mainLegalManager .subtitHero {color:#198356 !important}
.asesoramientoLegalManager h6 {font-size: 1.7rem; color: #198356; font-weight: 700; padding-bottom: 20px; }

.asesoramientoLegalManager {padding: 0; 
    background: rgba(232,241,237,1);
    background: -moz-linear-gradient(top, rgba(232,241,237,1) 0%, rgba(255,255,255,1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(232,241,237,1)), color-stop(100%, rgba(255,255,255,1)));
    background: -webkit-linear-gradient(top, rgba(232,241,237,1) 0%, rgba(255,255,255,1) 100%);
    background: -o-linear-gradient(top, rgba(232,241,237,1) 0%, rgba(255,255,255,1) 100%);
    background: -ms-linear-gradient(top, rgba(232,241,237,1) 0%, rgba(255,255,255,1) 100%);
    background: linear-gradient(to bottom, rgba(232,241,237,1) 0%, rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e8f1ed', endColorstr='#ffffff', GradientType=0 );
     }

@media (max-width: 767px) {
    .asesoramientoLegalManager h6 {padding-top: 40px; }
    .asesoramientoLegalManager img {margin-top: 40px; border-radius: 10px; }
    .optionsLegalManager .col {width: 100% !important;}
}

