
.boxContacto h6 {color:#0365A6; font-size: 1.1rem; font-weight: 700;}
.boxContacto p {color:#222; font-size: 0.9rem; font-weight: 400; margin-bottom:0}
.fndBoxContacto {min-height: 370px !important; border: 1px solid #E6E6E6; background-color: #fff; border-radius: 10px; padding: 40px 20px; min-height: 340px; margin-bottom: 40px;}
.containerBoxContacto {padding: 110px 0 70px 0;}
.contBoxContactoCorto {padding: 50px 0 50px 0;}
.linkPedirContacto {color:#FDB22A; text-decoration: none; border:0; background: transparent !important;}
.linkPedirContacto:hover {color:#0365A6; text-decoration: none;}

.bgContactoFooter {background: url("../../../assets/img/contacto/bgContacto.png") top center no-repeat;  }

.modal-350w {max-width: 350px !important;}
.modal-600w {max-width: 600px !important;}


.PhoneInput {
	display: block;
	width: 100%;
	height: calc(1.5em + .75rem + 2px);
	padding: .375rem .75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: .25rem;
	transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.PhoneInputCountry {width: 20%; float:left; margin-top: 5px !important;}


.PhoneInputInput {
	display: block;
	width: 70%;
	height: 100%;
	padding: 0;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 0;

    float:left
}


@media (max-width:  767px) {
    .fndBoxContacto {min-height: auto !important; }

    .fndBoxContacto { min-height: auto; margin-bottom: 40px;} 
    .containerBoxContacto {padding: 50px 0 30px 0;}
    .contBoxContactoCorto {padding: 30px 0 30px 0;}
}