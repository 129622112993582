.inicioMrBills .subtitHero{color:#541879 !important}
.titComiezaMrBills {color:#541879 !important; font-size: 1.5rem;}
.ComoFuncionaMrBills h6 {color:#541879 !important; font-size: 1.2rem;}
.ComoFuncionaMrBills p {color:#222 !important; font-size: 1rem;}
.ComoFuncionaMrBills .titHero {font-size: 2.1rem ;}
.numeroItem {  width: 80px;  height: 80px;  background: #F1E2FD;    border-radius: 50%; display: block; font-size: 40px; color:#951CFD !important; text-align: center; font-weight: bold; padding-top: 10px;}
.bgMrBillsPlanes {background: url("../../../assets/img/mrBill/Bg.png") top center repeat-x; padding: 150px 0 100px 0 !important; background-color: #54187a !important;}

.titConciergeMrBills {background-color: #C37CFF; width: 100%; padding: 14px; color:#fff; font-size:2rem; font-weight: bold; text-align: center; margin: 0; border-top-left-radius: 10px;  border-top-right-radius: 10px;}

.titMrBillsPlanes {color:#fff !important; font-size: 1.9rem; font-weight: bold;}

.bgMrBillsPlanes .card {border: 0; background-color: transparent;  border-top-left-radius: 0 !important;  border-top-right-radius: 0 !important;}
.bgMrBillsPlanes .card .card-body {border: 0; padding:20px 20px 40px 20px; background-color: #fff; border-bottom-left-radius: 10px;  border-bottom-right-radius: 10px; }
.bgMrBillsPlanes.card .card-body h3 {color:#0B6E6E; margin: 0; font-size: 1.9rem; }
.bgMrBillsPlanes .card .card-body h6 {color:#222; margin: 0; font-size: 1rem; font-weight: 300;  font-family: 'Nunito Sans', sans-serif !important ; padding-top: 5px; }


.listaMrBill .col {background: url("../../../assets/img/mrBill/tick.png") 15px 4px no-repeat; padding-left: 40px;  font-size: 14px; margin-bottom:0; color:#222; text-align: left; }

.planesMrBillWrap {max-width:700px !important;}

.precioOpcionBill {font-size: 1rem; margin: 0;}
.precioOpcionBill span {color:#7C7C7C !important}

@media (max-width: 767px) {
    .planesMrBillWrap {max-width:80% !important;}
    .precioOpcionBill {margin-bottom: 20px;}
}

