.bgFaqs {    
background:  url("../../../assets/img/home/fondoFAQSGs360Home.png") bottom right no-repeat rgba(245,245,245,1);
padding: 0 !important; margin:0 !important; padding-bottom: 150px !important; padding-top: 120px !important; 
}

.titFaq {color:#15385C!important; font-size: 1.7rem; font-weight: 300; margin: 0;}
.subTitFaq {color:#222 !important; font-size: 1.1rem; font-weight: 400; margin: 0;}

.seccionFaq {padding-bottom:100px;}
.seccionFaq h4 {font-size: 2rem; font-weight: 700; padding-top: 40px; padding-bottom: 20px;}

.contactoFaq {
  
background: rgba(246,249,253,1);
background: -moz-linear-gradient(top, rgba(246,249,253,1) 0%, rgba(255,255,255,1) 100%);
background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(246,249,253,1)), color-stop(100%, rgba(255,255,255,1)));
background: -webkit-linear-gradient(top, rgba(246,249,253,1) 0%, rgba(255,255,255,1) 100%);
background: -o-linear-gradient(top, rgba(246,249,253,1) 0%, rgba(255,255,255,1) 100%);
background: -ms-linear-gradient(top, rgba(246,249,253,1) 0%, rgba(255,255,255,1) 100%);
background: linear-gradient(to bottom, rgba(246,249,253,1) 0%, rgba(255,255,255,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6f9fd', endColorstr='#ffffff', GradientType=0 );

}

/**--- FAQS STYLES ----**/
.row-title {font-weight: 600; font-size: 16px;padding: 10px !important;}
.row-content-text {font-weight: 400; font-size: 14px;padding: 10px !important;}


@media (max-width: 767px) {
    .bgFaqs {background: #fff !important; padding: 70px 0 120px 0 !important; margin:0 !important}
        
}