.titH5 {color:#5AC3DC; text-transform: uppercase; font-size: 14px !important; padding-bottom: 20px; font-weight: 700 !important;}
.titH2 {color:#0365A6 !important;}

.bgGreyDegrade {    
background: rgba(237,237,237,1);
background: -moz-linear-gradient(left, rgba(237,237,237,1) 0%, rgba(246,246,246,1) 53%, rgba(255,255,255,1) 100%);
background: -webkit-gradient(left top, right top, color-stop(0%, rgba(237,237,237,1)), color-stop(53%, rgba(246,246,246,1)), color-stop(100%, rgba(255,255,255,1)));
background: -webkit-linear-gradient(left, rgba(237,237,237,1) 0%, rgba(246,246,246,1) 53%, rgba(255,255,255,1) 100%);
background: -o-linear-gradient(left, rgba(237,237,237,1) 0%, rgba(246,246,246,1) 53%, rgba(255,255,255,1) 100%);
background: -ms-linear-gradient(left, rgba(237,237,237,1) 0%, rgba(246,246,246,1) 53%, rgba(255,255,255,1) 100%);
background: linear-gradient(to right, rgba(237,237,237,1) 0%, rgba(246,246,246,1) 53%, rgba(255,255,255,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ededed', endColorstr='#ffffff', GradientType=1 );
border-radius: 5px;
padding: 18px 10px 18px 20px;
}

.bgGreyDegrade p {
    margin-bottom: 0;
    padding-left: 15px;
    font-size: 18px;
}

.pqGS360 {padding-top: 120px; padding-bottom: 120px;}

@media (max-width: 767px) {

    .pqGS360 {padding-top: 40px; padding-bottom: 40px;}
        
    .bgGreyDegrade img { 
        margin:0;
        }

    .bgGreyDegrade p {    
        padding-left: 15px;     
    }

}


