.bgPortada {background: url("../../../assets/img/home/fondoGs360Home.jpg") top center no-repeat;}
.bgSasWizard {background: url("../../../assets/img/sasWizard/fondoSasWizard.png") top center no-repeat;}
.bgRocketContracts {background: url("../../../assets/img/rocketContracts/fondoRocketContracts.png") top center repeat-x;}
.bgBrandineitor {background: url("../../../assets/img/brandineitor/fondoBrandineitor.png") top center ;}
.bgMrBill  {background: url("../../../assets/img/mrBill/fondoMrBills.png") top center no-repeat;}
.bg360Services {background: url("../../../assets/img/360services/fondoGS360Services.png") top center ;}
.bgLegalManager {background: url("../../../assets/img/legalManager/fondoLegalManager.png") top center ;}
.bgProveedores {background: url("../../../assets/img/proveedores/fondoProveedoresGS360Services.png") top center; background-size: cover;}
.bgRecursos {background: url("../../../assets/img/recursosDigitales/fondoDocumentosLegalManager.png") top center; background-size: cover;}
.bgMainFaqs {background: url("../../../assets/img/faq/fondoFAQ.png") top center; background-size: cover;}

.bgMainFaqs .titHero  {color: #0365A6 !important;}

@media (min-width:  1367px) {
    .bgSasWizard ,
    .bgMrBill,
    .bgBrandineitor,
    .bg360Services,
    .bgLegalManager {background-size: cover;}
}

@media (min-width:  1921px) {
    .bgInicial{ background-size: cover; }   
}

@media (max-width:  767px) {
   
}