.mainBrandineitor {padding: 100px 0;}
.mainBrandineitor h2 {color:#00C3C3 !important}
.mainBrandineitor p {font-size: 1rem; color:#222222; margin: 0;}
.mainBrandineitor h4 {color:#00C3C3 !important; font-size:2rem; margin: 0;}
.mainBrandineitor h6 {font-size: 1rem; line-height: 2rem; color:#222222; margin: 0; font-weight: 400 !important; font-family: 'Nunito Sans', sans-serif !important ;}
.optionBrandineitor {max-width: 800px !important; }
.listaBrandineitor .col {background: url("../../../assets/img/brandineitor/arrowBullet.png") 15px 4px no-repeat; padding-left: 30px;  font-size: 16px; margin-bottom:10px; color:#222; }
.listaBrandShield .col {background: url("../../../assets/img/brandineitor/iconCheck.png") 15px 4px no-repeat; padding-left: 40px;  font-size: 14px; margin-bottom:0; color:#222; }
.bgBrandMasServ {background-color: #E6F8F8 !important; padding: 80px 0; }
.bgBrandMasServ h2 {color: #006E6E !important; font-size: 2rem; font-weight: 700;}
.bgBrandMasServ .card {border: 0; background-color: transparent;}
.bgBrandMasServ .card .card-body {border: 0; padding:20px 20px 40px 20px; background-color: #fff; border-bottom-left-radius: 10px;  border-bottom-right-radius: 10px; }
.bgBrandMasServ .card .card-body h3 {color:#0B6E6E; margin: 0; font-size: 1.9rem; }
.bgBrandMasServ .card .card-body h6 {color:#222; margin: 0; font-size: 1rem; font-weight: 300;  font-family: 'Nunito Sans', sans-serif !important ; padding-top: 5px; }
.precioOpcionBrand {font-size: 1rem; margin: 0;}
.precioOpcionBrand span {color:#7C7C7C !important}
.textoOptionBrand {color:#222 !important; font-size: 1rem; font-weight: 400; line-height: 1.7rem; margin: 0 0 30px 0;}


@media (max-width: 767px) {
    .mainBrandineitor {padding: 50px 0;}
    .precioOpcionBrand {margin-bottom: 20px;}
}

