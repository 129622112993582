.filtroProveedores .input-group-text {background: transparent;}
.searchInput {border-left: 0 !important; padding-left:0 !important;}
.filtroProveedores .selectCategory {width: 50%;}
.filtroProveedores p {font-size: 0.9rem;}
.resultadoProveedor h5 {color:#ED701D; font-size: 1.1rem; font-weight: 700; margin:0}
.resultadoProveedor h5 span {color:#5BC102; font-size: 11px; font-weight: 700; background: url("../../../assets/img/proveedores/iconVerificado.png") 0 3px no-repeat; padding-left: 15px; margin-left: 15px;}
.textoProveedores {font-size: 0.9rem; margin:0 }
.borderProveedor {border: 1px solid #E0E1E1; border-radius: 10px; padding: 13px; margin-bottom: 15px;}


@media (max-width: 767px) {
    .resultadoProveedor h5 {margin-top:15px}
    .textoProveedores {font-size: 1rem; margin:0 }
}


