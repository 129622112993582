.rocketContracts {min-height: 865px; background: url("../../../assets/img/home/bgRocketContracts.svg") top center no-repeat; margin-top: 140px;}

.listaRocket li p {background: url("../../../assets/img/home/arrowBullet.png") 0 4px no-repeat; padding-left: 15px;  font-size: 16px; margin-bottom:10px; color:#535353}

.listaControlEnTiempoReal {padding: 20px; min-height:180px !important;}
.listaControlEnTiempoReal h2 {font-size: 1.8rem; color:#5AC3DC; font-weight: 700;}
.listaControlEnTiempoReal p {font-size: 1rem; color:#222; font-weight: 400;}

.bgBioseguridad { 
padding:90px 0 70px 0;

background: rgba(250,251,254,1);
background: -moz-linear-gradient(top, rgba(250,251,254,1) 0%, rgba(246,246,246,1) 27%, rgba(255,255,255,1) 100%);
background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(250,251,254,1)), color-stop(27%, rgba(246,246,246,1)), color-stop(100%, rgba(255,255,255,1)));
background: -webkit-linear-gradient(top, rgba(250,251,254,1) 0%, rgba(246,246,246,1) 27%, rgba(255,255,255,1) 100%);
background: -o-linear-gradient(top, rgba(250,251,254,1) 0%, rgba(246,246,246,1) 27%, rgba(255,255,255,1) 100%);
background: -ms-linear-gradient(top, rgba(250,251,254,1) 0%, rgba(246,246,246,1) 27%, rgba(255,255,255,1) 100%);
background: linear-gradient(to bottom, rgba(250,251,254,1) 0%, rgba(246,246,246,1) 27%, rgba(255,255,255,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fafbfe', endColorstr='#ffffff', GradientType=0 );
}

.bgBioseguridad h2 { font-size: 2rem; color:#222222 }

.bgBioseguridad p { font-size: 1rem; color:#222222 }

.bgMedioAmbiente {background: url("../../../assets/img/rocketContracts/bgMedioAmbiente.png") top center repeat-y; min-height: 325px; padding: 50px 0;}
.bgMedioAmbiente h2 {font-size: 2rem; color:#fff; font-weight: 300; }
.bgMedioAmbiente p { font-size: 1rem; color:#fff }

@media (max-width:  767px) {
    .rocketContracts {min-height: auto; background: none; margin-top: 100px;}
    .bgBioseguridad {padding:30px 0 30px 0;}
}


@media (min-width:  1367px) {
    .bgMedioAmbiente {background-size: cover;}
}
