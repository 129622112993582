.hero {min-height: 760px;}
.heroCorto {padding-top: 150px; }

.heroTextFaq {color:#5AC3DC !important; font-size: 1.1rem; font-weight: 700;  margin: 0; line-height: 1.8rem; }

.heroImgPortada {position: absolute; right: -670px; top: -110px; z-index: 0;}
.heroImgSasWizard {position: absolute; right: -170px; top: 100px; z-index: 0;}
.heroRocketContracts {position: absolute; right: -370px; top: 20px; z-index: 0; }
.heroBrandineitor {position: absolute; right: -270px; top: 90px; z-index: 0; }
.heroMrBills {position: absolute; right: -170px; top: 100px; z-index: 0; }
.heroGS360 {position: absolute; right: -270px; top: 100px; z-index: 0; }
.heroLegalManager {max-width:700px; position: absolute; right: -200px; top: 120px; z-index: 0; }

.logoMarca{ margin:0 0 20px 3px;}

.scrollDown {width: 25px; position: absolute; bottom:40px; }

.buscarFaq {padding: 70px 0 0 0;}
.buscarFaq .input-group-text {background: transparent;}
.buscarFaq .searchInput {border-left: 0 !important; padding-left:0 !important; }

@media (max-width: 767px) {
    .hero {min-height: auto; padding-top: 150px;}
    .scrollDown {width: 25px; position: inherit; bottom:0; margin:40px auto;}
    .heroImgPortada {position: relative; right: -30px; top:0; z-index: 0; max-width: 300px; margin:0px auto;}
    .heroImgSasWizard {position: relative; right: -30px; top:0; z-index: 0; max-width: 300px; margin:20px auto;}
    .heroRocketContracts  {position: relative; right: 0px; top:-100px; z-index: 0; max-width: 300px; margin:20px auto;}
    .heroBrandineitor {position: relative; right: 0px; top:-20px; z-index: 0; max-width: 300px; margin:20px auto;}
    .heroMrBills {position: relative; right: 0px; top:-10px; z-index: 0; max-width: 200px; margin:20px auto;}
    .heroGS360 {position: relative; right: 0px; top:-10px; z-index: 0; max-width: 300px; margin:20px auto;}
    .heroLegalManager {position: relative; right: 0px; top:-10px; z-index: 0; max-width: 300px; margin:20px auto;}
    .buscarFaq {padding: 30px 0 0 0;}
}


